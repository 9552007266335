// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../src/pages/join_us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-marketing-js": () => import("./../src/pages/products/marketing.js" /* webpackChunkName: "component---src-pages-products-marketing-js" */),
  "component---src-pages-products-openshop-js": () => import("./../src/pages/products/openshop.js" /* webpackChunkName: "component---src-pages-products-openshop-js" */),
  "component---src-pages-products-payment-js": () => import("./../src/pages/products/payment.js" /* webpackChunkName: "component---src-pages-products-payment-js" */),
  "component---src-pages-products-sale-js": () => import("./../src/pages/products/sale.js" /* webpackChunkName: "component---src-pages-products-sale-js" */),
  "component---src-pages-system-product-content-platform-js": () => import("./../src/pages/systemProduct/contentPlatform.js" /* webpackChunkName: "component---src-pages-system-product-content-platform-js" */),
  "component---src-pages-system-product-hui-life-platform-js": () => import("./../src/pages/systemProduct/huiLifePlatform.js" /* webpackChunkName: "component---src-pages-system-product-hui-life-platform-js" */),
  "component---src-pages-system-product-hui-zhuan-qian-platform-js": () => import("./../src/pages/systemProduct/huiZhuanQianPlatform.js" /* webpackChunkName: "component---src-pages-system-product-hui-zhuan-qian-platform-js" */),
  "component---src-pages-system-product-private-domain-js": () => import("./../src/pages/systemProduct/privateDomain.js" /* webpackChunkName: "component---src-pages-system-product-private-domain-js" */),
  "component---src-pages-system-product-targeted-delivery-solution-js": () => import("./../src/pages/systemProduct/targetedDeliverySolution.js" /* webpackChunkName: "component---src-pages-system-product-targeted-delivery-solution-js" */),
  "component---src-pages-system-product-trade-platform-js": () => import("./../src/pages/systemProduct/tradePlatform.js" /* webpackChunkName: "component---src-pages-system-product-trade-platform-js" */)
}

