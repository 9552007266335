module.exports = hmKey => {
  // eslint-disable-next-line
  var _hmt = _hmt || []
  ;(function() {
    var hm = document.createElement('script')
    hm.src = `https://hm.baidu.com/hm.js?${hmKey}`
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}
