const initBaiduHm = require('../../baiduHm')
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// https://github.com/gatsbyjs/gatsby/pull/666
// reach/router不支持hash路由模式

// const React = require('react')
// const { hashHistory, Router } = require('@reach/router/lib/history')
// const { render } = require('react-dom')
//
// exports.replaceDOMRender = ({ routes, onUpdate }) => {
//   render(
//     <Router
//       history={hashHistory}
//       routes={routes}
//       // render={applyRouterMiddleware(useScroll(shouldUpdateScroll))}
//       onUpdate={onUpdate}
//     />
//   )
// }

exports.onInitialClientRender = () => {
  initBaiduHm('d396084dc6a823a839ca29caf68c88aa')
}

exports.onClientEntry = () => {
  // page-data.json后面加上时间戳 临时解决s3上没有设置cache-control的问题
  // gatsby的prefetc是通过xhr实现的 override一下
  var rawOn = XMLHttpRequest.prototype.open
  XMLHttpRequest.prototype.open = function(...args) {
    const [method, url, ...rest] = args
    if (
      url.includes('page-data.json') ||
      url.includes('app-data.json') ||
      url.includes('.kezaihui.com')
    )
      return rawOn.call(this, method, `${url}?key=${Date.now()}`, ...rest)
    return rawOn.call(this, ...args)
  }

  // ;[].slice.call(document.getElementsByTagName('link')).forEach(node => {
  //   if (node.href.includes('page-data.json') && !node.href.includes('key=')) {
  //     var href = node.href
  //     node.parentNode.removeChild(node)
  //     var tempLink = document.createElement('link')
  //     tempLink.href = href + `?key=${Date.now()}`
  //     tempLink.crossorigin = 'anonymous'
  //     tempLink.as = 'fetch'
  //     document.head.appendChild(tempLink)
  //   }
  // })
}
